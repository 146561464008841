import { ReactComponent as airplane } from "./airplane.svg";
import { ReactComponent as arrowDown } from "./arrowDown.svg";
import { ReactComponent as arrowUp } from "./arrowUp.svg";
import { ReactComponent as bell } from "./bell.svg";
import { ReactComponent as call } from "./call.svg";
import { ReactComponent as camera } from "./camera.svg";
import { ReactComponent as cart } from "./cart.svg";
import { ReactComponent as chat } from "./chat.svg";
import { ReactComponent as check } from "./check.svg";
import { ReactComponent as chevronSmallRight } from "./chevronSmallRight.svg";
import { ReactComponent as close } from "./close.svg";
import { ReactComponent as coupon } from "./coupon.svg";
import { ReactComponent as deleteIcon } from "./deleteIcon.svg";
import { ReactComponent as dragHandle } from "./dragHandle.svg";
import { ReactComponent as faqQuestion } from "./faqQuestion.svg";
import { ReactComponent as heart } from "./heart.svg";
import { ReactComponent as heartFill } from "./heartFill.svg";
import { ReactComponent as heartFillSmall } from "./heartFillSmall.svg";
import { ReactComponent as heartSmall } from "./heartSmall.svg";
import { ReactComponent as leftArrow } from "./leftArrow.svg";
import { ReactComponent as logo } from "./logo.svg";
import { ReactComponent as m } from "./m.svg";
import { ReactComponent as menuCategory } from "./menuCategory.svg";
import { ReactComponent as menuHistory } from "./menuHistory.svg";
import { ReactComponent as menuHome } from "./menuHome.svg";
import { ReactComponent as menuMyPage } from "./menuMyPage.svg";
import { ReactComponent as menuSearch } from "./menuSearch.svg";
import { ReactComponent as minus } from "./minus.svg";
import { ReactComponent as naverPay } from "./naverPay.svg";
import { ReactComponent as notice } from "./notice.svg";
import { ReactComponent as plus } from "./plus.svg";
import { ReactComponent as search } from "./search.svg";
import { ReactComponent as share } from "./share.svg";
import { ReactComponent as sheetHandle } from "./sheetHandle.svg";
import { ReactComponent as zoomIn } from "./zoomIn.svg";

export default {
  logo,
  menuHome,
  menuCategory,
  menuHistory,
  menuMyPage,
  menuSearch,
  chevronSmallRight,
  leftArrow,
  check,
  plus,
  close,
  deleteIcon,
  notice,
  arrowDown,
  search,
  call,
  faqQuestion,
  dragHandle,
  camera,
  arrowUp,
  airplane,
  cart,
  heart,
  heartFill,
  heartSmall,
  heartFillSmall,
  chat,
  coupon,
  zoomIn,
  share,
  m,
  minus,
  naverPay,
  sheetHandle,
  bell,
};
